import React, { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTypedSelector } from '../hooks/useTypedSelector'
import styles from './css/FaqPage.module.css'
import Extensions from '../models/extensions'
import { EServices, TableHelper } from '../types/tableHelper'
import { CurrencyData } from '../types/currency/currency'

const urlRegex = /{(?<text>[^|{]+)\|+(?<url>[^|}]+)\|*(?<option>[^|}]+)?}/gm
const getTextJsxWithUrl = (source: string): JSX.Element => {
	source ??= ''
	let result: JSX.Element[] = []
	let start = 0

	for (const match of source.matchAll(urlRegex)) {
		let end = match.index
		result.push(<>{source.substring(start, end)}</>)
		const url = match.groups?.url
		const text = match.groups?.text
		const option = match.groups?.option
		const hasChars = url?.length > 0

		if (!hasChars) {
			result.push(<>{text}</>)
		} else {
			const urlText: JSX.Element = {
				d: <>{text}</>,
			}[option] || <strong>{text}</strong>

			const className =
				{
					d: 'btn-simple-hov',
				}[option] || undefined

			if (url.startsWith('http')) {
				result.push(
					<a href={url} target='_blank' rel='nofollow noreferrer' className={className}>
						{urlText}
					</a>
				)
			} else if (url.startsWith('mailto')) {
				result.push(
					<a href={url} className={className}>
						{urlText}
					</a>
				)
			} else {
				result.push(
					<NavLink to={url} className={className}>
						{urlText}
					</NavLink>
				)
			}
		}

		start = match.index + match[0].length
	}

	if (start !== source.length) {
		result.push(<>{source.substring(start, source.length)}</>)
	}

	return (
		<>
			{result.map((x, i) => (
				<React.Fragment key={i}>{x}</React.Fragment>
			))}
		</>
	)
}

interface MainTitleProps {
	text: string
}
const MainTitle: FC<MainTitleProps> = props => {
	return (
		<div className={styles.facMainTitle}>
			<h3>{props.text ?? ''}</h3>
		</div>
	)
}

interface TitleProps {
	text: string
	italic?: boolean
}
const Title: FC<TitleProps> = props => {
	return <p className={styles.facTitle}>{props.italic ? <i>{props.text}</i> : <>{props.text}</>}</p>
}

interface ContentProps {
	text: string
}
const Content: FC<ContentProps> = props => {
	const jsx = getTextJsxWithUrl(props.text)

	return <p className={styles.facTextContent}>{jsx}</p>
}

interface ImageProps {
	src: string
	alt?: string
	height: number
	width?: number
}
const Image: FC<ImageProps> = props => {
	const imageStyle = props.width && props.width != null ?
		{ width: `${props.width}`, height: props.height } :
		{ maxWidth: '100%', height: props.height };

	return (
		<div className={styles.facImg}>
			<img src={props.src} alt={props.alt ?? 'img'} style={imageStyle} />
		</div>
	)
}

interface ListProps {
	title: JSX.Element
	items: JSX.Element[]
	numberList?: boolean
}
const List: FC<ListProps> = props => {
	return (
		<p className={styles.facTextContent}>
			{props.title}
			{!props.numberList ? (
				<ul>
					{props.items?.map((x, i) => {
						return <li key={i}>{x}</li>
					})}
				</ul>
			) : (
				<ol>
					{props.items?.map((x, i) => {
						return <li key={i}>{x}</li>
					})}
				</ol>
			)}
		</p>
	)
}

const FaqPage: FC = () => {
	const location = useLocation()

	const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const [currentHash, setCurrentHash] = useState<string>()
	const [title, setTitle] = useState<string>()
	const [content, setContent] = useState<React.ReactNode>()

	useEffect(() => {
		document.title = 'vvTable - FAQ'
	}, [])

	useEffect(() => {
		const hash = location.hash.substring(1)
		setCurrentHash(hash)

		
		let _title: string
		let _jsx: React.ReactNode
		
		const currencyList = [...CurrencyData.AllCurrencies.keys()]
		let currencyCodes = currencyList.join(', ')

		switch (hash) {
			case 'profile':
				_title = { 0: 'Профиль' }[currentLang] || 'Profile'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={1}>
							<MainTitle text='Зачем нужен профиль?' />
							<List
								title={<>Ваш профиль – это Ваш личный кабинет. В нем содержится много полезной информации и функций:</>}
								items={[
									<>
										Сведения о Вашей текущей подписке (вид подписки и её срок до окончания) и баланс. Здесь же Вы можете нажать на кнопку
										«Пополнить» и Вас перенаправит на страницу пополнения баланса.
									</>,
									<>Поле для ввода промокода.</>,
									<>
										Реферальная система: Ваш реферальный код. Также свой реферальный код можно
										скопировать в виде ссылки, нажав на кнопку «Скопировать ссылку» под кодом.
									</>,
									getTextJsxWithUrl('Список Ваших операций. Любые свои операции Вы сможете просмотреть в {профиле|/profile}.'),
									<>
										Приостановка подписки. Данная функция позволяет заморозить Вашу подписку на 14 дней, без возможности ее досрочного восстановления.
										Между текущей и следующей приостановкой должно пройти 90 дней
									</>,
								]}
								numberList={true}
							/>
							<Image src='Resources/Faq/Profile/1_ru.png' alt='profile' height={350} />
							<Title text='Примечание' />
							<Content text='Нажав на свой никнейм, Вы будете перенаправлены на свой Steam аккаунт.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={1}>
						<MainTitle text='Why is a profile needed?' />
						<List
							title={<>Your profile is your personal account. It contains a lot of useful information and features:</>}
							items={[
								<>
									Information about your current subscription (type of subscription and its expiration date) and balance. Here you can also
									click on the "Deposit" button and you will be redirected to the balance replenishment page.
								</>,
								<>Field for entering a promo code.</>,
								<>
									Referral system: Your referral code. You can also copy your referral code as a link
									by clicking on the "Copy link" button below the code.
								</>,
								getTextJsxWithUrl('List of your transactions. You can view any of your transactions in your {profile|/profile}.'),
								<>
									Suspending your subscription. This function allows you to freeze your subscription for 14 days, without the possibility of early restoration.
									There must be 90 days between the current and next suspension
								</>,
							]}
							numberList={true}
						/>
						<Image src='Resources/Faq/Profile/1_en.png' alt='profile' height={350} />
						<Title text='Note' />
						<Content text='By clicking on your nickname, you will be redirected to your Steam account.' />
					</div>
				)
				break
			case 'table':
				_title = { 0: 'Таблица' }[currentLang] || 'Table'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={2}>
							<MainTitle text='Как начать пользоваться таблицей?' />
							<Content text='Чтобы начать пользоваться нашей таблицей, Вам, в первую очередь, необходимо {авторизоваться|#auth} на сайте. Если Вы хотите раскрыть весь потенциал нашего сервиса, Вам в этом поможет {подписка|#sub}, которая откроет доступ ко всему функционалу таблицы.' />
							<Image src='Resources/Faq/Table/1_ru.png' alt='table' height={400} />
							<MainTitle text='Как настроить таблицу?' />
							<Content text='Для того, чтобы нашей таблицей было удобно пользоваться, Вам необходимо настроить её под себя.' />
							<Title text='1. Выбрать игру' italic={true} />
							<List
								title={<>Список игр:</>}
								items={[
									getTextJsxWithUrl('{CS2|https://blog.counter-strike.net/ru/|d}'),
									getTextJsxWithUrl('{Dota 2|https://www.dota2.com/home?l=russian|d}'),
									getTextJsxWithUrl('{Team Fortress 2|https://www.teamfortress.com/|d}'),
									getTextJsxWithUrl('{Rust|https://rust.facepunch.com/|d}'),
								]}
							/>
							<Content text='Для предметов CS2 и Dota 2 присутствует 7-дневная блокировка на обмен. Если блокировка есть, то её срок отображается под названием предмета.' />
							<Title text='2. Выбрать сервисы для сравнения' italic={true} />
							<List
								title={<>Сервисы:</>}
								
								items={[
									getTextJsxWithUrl(
										`Площадки (${Extensions.GetEnumValues(EServices)
											.filter(x => TableHelper.CheckServiceIsMarket(x))
											.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`
									),
									getTextJsxWithUrl(
										
										`Обменники (${Extensions.GetEnumValues(EServices)
											.filter(x => !TableHelper.CheckServiceIsMarket(x) && !TableHelper.CheckServiceIsRoulette(x))
											.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`
									),
									getTextJsxWithUrl(
										`Рулетки (${Extensions.GetEnumValues(EServices)
											.filter(x => !TableHelper.CheckServiceIsMarket(x) && TableHelper.CheckServiceIsRoulette(x))
											.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`
									)
								]}
							/>
							<Content text='Пример: если Вы хотите вывести вещь со Steam и выгодно продать её на Buff, тогда первым сервисом выбирайте Steam, а вторым – Buff.' />
							<Content text='Некоторые сервисы доступны только с подпиской PRO, об этом можно почитать на странице {подписки|/sub}.' />
							<Title text='3. Выбрать нужные фильтры, исключив какие-либо предметы или поставив отображение только конкретных' italic={true} />
							<List
								title={<>Статусы фильтров:</>}
								items={[
									<>Пустой статус фильтра означает, что он включён в таблицу</>,
									<>X – фильтр исключён из таблицы</>,
									<>✓ – в таблице только данный фильтр</>,
								]}
							/>
							<Content text='После выбора фильтров необходимо нажать на кнопку «Обновить» справа над таблицей, чтобы изменения вступили в силу.' />
							<Title text='4. Выбрать нужную валюту' italic={true} />
							<Content text= {`Возможные валюты: ${currencyCodes}`} />
							<Title text='5. Выбрать сервис для отображения количества последних продаж и их период' italic={true} />
							<Content text='Если у Вас нет подписки PRO, тогда Вам доступен только 1 период продаж – неделя.' />
							<Title text='Примечание' />
							<Content text='Если у Вас нет подписки, цены на предметы будут ограничены до 1.5$. Если Вы хотите сохранить несколько разных настроек таблицы, то купив подписку, Вам откроется возможность создавать профили, в которых будут сохраняться все выбранные Вами настройки. Прочитать подробнее и купить подписку можно {здесь|/sub}.' />
							<MainTitle text='Что показывает таблица?' />
							<List
								title={<>Наша таблица предоставляет Вам следующую информацию по предметам:</>}
								items={[
									<>Время последнего обновления первого и второго сервисов (слева над таблицей)</>,
									<>Название предмета</>,
									<>
										Инструменты: скопировать название предмета, добавить его в избранное, добавить его в чёрный список (исключить из
										отображения), посмотреть цену на разных сервисах (только для подписки PRO), убрать до изменения цены (предмет не будет
										отображаться, пока на него не обновится цена). Список избранного и чёрный список находятся рядом с фильтрами в разделе
										«Списки». Предметы из этих списков можно удалять как по отдельности, так и все сразу.
									</>,
									<>Цена предмета на первом и втором сервисах</>,
									<>
										Прибыль, которую Вы получите, если купите предмет на первом сервисе и продадите его на втором с учетом комиссии и
										бонусов
									</>,
									<>Ссылка на предмет на первом и втором сервисах. Под ссылкой также указывается количество предметов</>,
								]}
							/>
							<List
								title={<>Сортировку предметов можно поставить по:</>}
								items={[
									<>Количеству продаж в выбранном сервисе</>,
									<>Цене первого или второго сервисов</>,
									<>Прибыли</>,
									<>Количеству предметов на первом или втором сервисах</>,
								]}
							/>
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={2}>
						<MainTitle text='How to start using the table?' />
						<Content text='To start using our table, you first need to {login|#auth} on the site. If you want to unleash the full potential of our service, {subscription|#sub} will help you with this, which will open access to all the functionality of the table.' />
						<Image src='Resources/Faq/Table/1_en.png' alt='table' height={400} />
						<MainTitle text='How to setup a table?' />
						<Content text='In order for our table to be convenient to use, you need to setup it for yourself.' />
						<Title text='1. Select game' italic={true} />
						<List
							title={<>List of games:</>}
							items={[
								getTextJsxWithUrl('{CS2|https://blog.counter-strike.net/|d}'),
								getTextJsxWithUrl('{Dota 2|https://www.dota2.com/home?l=english|d}'),
								getTextJsxWithUrl('{Team Fortress 2|https://www.teamfortress.com/|d}'),
								getTextJsxWithUrl('{Rust|https://rust.facepunch.com/|d}'),
							]}
						/>
						<Content text='There is a 7-day trade lock for CS2 and Dota 2 items. If there is a blocking, then its duration is displayed under the name of the item.' />
						<Title text='2. Select services to compare' italic={true} />
						<List
							title={<>Services:</>}
							items={[
								getTextJsxWithUrl(
									`Markets (${Extensions.GetEnumValues(EServices)
										.filter(x => TableHelper.CheckServiceIsMarket(x))
										.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`
								),
								getTextJsxWithUrl(
									
									`Exchangers (${Extensions.GetEnumValues(EServices)
										.filter(x => !TableHelper.CheckServiceIsMarket(x) && !TableHelper.CheckServiceIsRoulette(x))
										.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`
								),
								getTextJsxWithUrl(
									`Roulette (${Extensions.GetEnumValues(EServices)
										.filter(x => !TableHelper.CheckServiceIsMarket(x) && TableHelper.CheckServiceIsRoulette(x))
										.map(x => `{${TableHelper.GetServiceName(x)}|${Extensions.GetReferralLink(x)}|d}`).join(', ')})`)
							]}
						/>
						<Content text='Example: if you want to withdraw an item from Steam and sell it profitably on Buff, then select Steam as the first service, and Buff as the second.' />
						<Content text='Some services are available only with a PRO subscription, you can read about it on the {subscription|/sub} page.' />
						<Title text='3. Select the desired filters by excluding any items or displaying only specific ones' italic={true} />
						<List
							title={<>Filter statuses:</>}
							items={[
								<>An empty filter status means that it is included in the table</>,
								<>X – filter excluded from table</>,
								<>✓ – only this filter in the table</>,
							]}
						/>
						<Content text='After selecting the filters, you must click on the «Update» button on the right above the table for the changes will updated.' />
						<Title text='4. Select the desired currency' italic={true} />
						<Content text={`Possible currencies: ${currencyCodes}`} />
						<Title text='5. Select a service to display the number of last sales and their period' italic={true} />
						<Content text='If you do not have a PRO subscription, then only 1 sales period is available to you - a week.' />
						<Title text='Note' />
						<Content text='If you do not have a subscription, item prices will be capped at $1.5. If you want to save several different table settings, then by purchasing a subscription, you will have the opportunity to create profiles in which all the settings you have chosen will be saved. Read more and buy a subscription {here|/sub}.' />
						<MainTitle text='What does the table show?' />
						<List
							title={<>Our table provides you with the following information on items:</>}
							items={[
								<>Time of the last update of the first and second services (on the left above the table)</>,
								<>Item name</>,
								<>
									Tools: copy the name of the item, add it to favorites, add it to the black list (exclude from display), see the price on
									different services (only for PRO subscription), remove before the price changes (the item will not be displayed until the
									price is updated for it ). The list of favorites and the black list are located next to the filters in the «Lists» section.
									Items from these lists can be removed individually or all at once.
								</>,
								<>The price of the item on the first and second services</>,
								<>
									Profit that you will receive if you buy an item on the first service and sell it on the second, including commission and
									bonuses
								</>,
								<>Link to the item on the first and second services. The number of items is also indicated under the link.</>,
							]}
						/>
						<List
							title={<>Items can be sorted by:</>}
							items={[
								<>The number of sales in the selected service</>,
								<>The price of the first or second service</>,
								<>Profit</>,
								<>The number of items on the first or second services</>,
							]}
						/>
					</div>
				)
				break
			case 'balance':
				_title = { 0: 'Баланс' }[currentLang] || 'Balance'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={3}>
							<MainTitle text='Как пополнить баланс?' />
							<Image src='Resources/Faq/Balance/1_ru.png' alt='balance' height={200}/>
							<List
								title={
									<>
										Team Fortress 2 (Mann Co. Supply Crate Key) является валютой сайта. Вы можете пополнить баланс тремя спосабами:
									</>
								}
								items={[
									<>Через нашего бота в Steam</>,
									<>С помощью платежной системы FreeKassa</>,
									<>Криптовалютой</>,
								]}
								/>
							<List
								title={
									<>
										Для этого Вам необходимо:
									</>
								}
								items={[
									getTextJsxWithUrl('Перейти  на страницу {Пополнение|/balance}.'),
									<>Выбрать способ оплаты</>,
									<>Нажать на кнопку «Пополнить».</>,
									
								]}
								numberList={true}
								/>
							<Content text='Обязательно проверяйте все ссылки, прежде чем отправить свои предметы. Также убедитесь, что отправляете обмен именно нашему боту: список наших ботов можно найти в разделе {безопасность|#security} в FAQ. Только так Вы сможете не стать жертвой мошенников.' />
							<Title text='Примечание' />
							<Content text='Если у Вас возникли какие-либо проблемы с пополнением баланса, обязательно обратитесь в нашу поддержку в соц. сетях или по почте {support@tablevv.com|mailto:support@tablevv.com}, подробно описав Вашу ситуацию. Также баланс, который Вы пополнили в течение 48 часов, подлежит возврату, если он не был использован. Купленную подписку вернуть уже нельзя.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={3}>
						<MainTitle text='How to replenish the balance?' />
						<Image src='Resources/Faq/Balance/1_en.png' alt='balance' height={200} />
						<List
							title={
								<>
									Team Fortress 2 (Mann Co. Supply Crate Key) is the site currency. You can top up your balance in three ways:
								</>
							}
							items={[
								<>Through our bot on Steam</>,
								<>Using the FreeKassa payment system</>,
								<>Cryptocurrency</>
							]}
							/>
						<List
							title={
								<>To do this you need:</>
							}
							items={[
								getTextJsxWithUrl('Go to the {Balance|/balance} page.'),
								<>Select payment method</>,
								<>Click on the “Top up” button.</>,
							]}
							numberList={true}
						/>
						<Content text='Be sure to check all links before submitting your items. Also make sure you send the exchange to our bot: a list of our bots can be found in the {security|#security} section of the FAQ. This is the only way you can avoid becoming a victim of scammers.' />
						<Title text='Note' />
						<Content text='If you have any problems with replenishing the balance, be sure to contact our support in the social. networks or by mail {support@tablevv.com|mailto:support@tablevv.com}, describing your situation in detail. Also, the balance that you topped up within 48 hours is refundable if it has not been used. A purchased subscription cannot be returned.' />
					</div>
				)
				break
			case 'sub':
				_title = { 0: 'Подписка' }[currentLang] || 'Subscription'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={3}>
							<MainTitle text='Для чего нужна подписка?' />
							<Content text='Подписка позволяет получить доступ к таким ограниченным функциям таблицы, как: дополнительные площадки для сравнения, безлимитные цены, просмотр цены на разных сервисах, отображение всех периодов продаж и профили таблицы. Все эти преимущества варьируются в зависимости от уровня Вашей подписки. Купить подписку, посмотреть характеристики каждой и цены можно на странице {подписки|/sub}. Также подписку можно получить, использовав промокод. Активировать промокод на подписку можно только в том случае, если у Вас нет активной подписки.' />
							<Image src='Resources/Faq/Subs/1_ru.png' alt='subs' height={320} />
							<Title text='Примечание' />
							<Content text='Ваша текущая подписка обозначается постфиксом на вашем нике. Если у Вас уже есть подписка BASE и Вы решили купить подписку PRO, оставшееся время подписки перенесется пропорционально цене на подписку PRO.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={3}>
						<MainTitle text='What is a subscription for?' />
						<Content text='A subscription allows you to access limited table features such as: additional comparison sites, unlimited prices, price view on different services, display of all sales periods and table profiles. All of these benefits vary depending on your subscription level. You can buy a subscription, see the characteristics of each and the prices on the {subscription|/sub} page. You can also get a subscription using a promo code. You can activate a promotional code for a subscription only if you do not have an active subscription.' />
						<Image src='Resources/Faq/Subs/1_en.png' alt='subs' height={320} />
						<Title text='Note' />
						<Content text='Your current subscription is indicated by a postfix on your nickname. If you already have a BASE subscription and decide to buy a PRO subscription, the remaining subscription time will be transferred in proportion to the price of the PRO subscription.' />
					</div>
				)
				break
			case 'support':
				_title = { 0: 'Поддержка' }[currentLang] || 'Support'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={4}>
							<MainTitle text='Как обратиться за помощью?' />
							<Content text='При возникновении любых проблем в использовании нашего сайта обязательно обращайтесь за помощью в нашу поддержку. Связаться с поддержкой можно любым удобным для Вас способом: в Telegram, VK или по почте {support@tablevv.com|mailto:support@tablevv.com}. Мы обязательно ответим Вам в кратчайшие сроки и поможем решить Вашу проблему. Все контакты Вы можете найти в разделе {поддержка|/support}.' />
							<Image src='Resources/Faq/Support/1_ru.png' alt='support' height={350} />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={4}>
						<MainTitle text='How to contact support?' />
						<Content text='If you have any problems using our site, be sure to contact our support for help. You can contact support in any way convenient for you: in Telegram, VK or by mail {support@tablevv.com|mailto:support@tablevv.com}. We will definitely answer you as soon as possible and help you solve your problem. You can find all contacts in the {support|/support} section.' />
						<Image src='Resources/Faq/Support/1_en.png' alt='support' height={350} />
					</div>
				)
				break
			case 'settings':
				_title = { 0: 'Настройки' }[currentLang] || 'Settings'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={5}>
							<MainTitle text='Что можно настроить?' />
							<List
								title={<>В настройках Вы можете:</>}
								items={[
									<>Указать свою ссылку на обмен.</>,
									<>
										Привязать свои соц. сети (VK и Telegram). За подписку на наши соц. сети Вы получите постоянную скидку в 1 ключ TF2 на
										приобретение подписки.
									</>,
									<>
										Настроить комиссии и бонусы сервисов. По умолчанию указаны общие значения, но, возможно, у Вас пониженная комиссия или
										имеются какие-то бонусы, поэтому Вы можете указать любые значения, какие Вам будут удобны. Узнать свою комиссию и бонусы
										можно на сайте сервисов.
									</>,
								]}
							/>
							<Image src='Resources/Faq/Settings/1_ru.png' alt='settings' height={400} />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={5}>
						<MainTitle text='What can be configured?' />
						<List
							title={<>In the settings you can:</>}
							items={[
								<>Provide your trade url.</>,
								<>
									Link your social networks (VK and Telegram). For subscribing to our social media network you will receive a permanent
									discount of 1 TF2 key for the purchase of a subscription.
								</>,
								<>
									Set up commissions and service bonuses. By default, the general values are indicated, but perhaps you have a lower
									commission or some bonuses, so you can specify any values that are convenient for you. You can find out your commission and
									bonuses on the services website.
								</>,
							]}
						/>
						<Image src='Resources/Faq/Settings/1_en.png' alt='settings' height={400} />
					</div>
				)
				break
			case 'referrals':
				_title = { 0: 'Реферальная система' }[currentLang] || 'Referral system'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={6}>
							<MainTitle text='Где найти свой реферальный код?' />
							<Content text='Свой реферальный код можно найти в {профиле|/profile}. Его также можно скопировать в виде ссылки.' />
							<Image src='Resources/Faq/Referral/1_ru.png' alt='referral' height={200} />
							<MainTitle text='Какие награды Вы получаете за рефералов?' />
							<Content text='За каждые 5 рефералов, которые купили подписку, Вы получаете 1 ключ TF2 (Mann Co. Supply Crate Key). Ключи, полученные за рефералов, можно вывести в свой инвентарь в Steam: для этого необходимо запросить вывод ключей через поддержку.' />
							<Title text='Примечание' />
							<Content text='Использовать реферальный код можно только 1 раз. Использовать свой реферальный код нельзя.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={6}>
						<MainTitle text='Where can I find my referral code?' />
						<Content text='You can find your referral code in {profile|/profile}. It can also be copied as a link.' />
						<Image src='Resources/Faq/Referral/1_en.png' alt='referral' height={200} />
						<MainTitle text='What rewards do you receive for referrals?' />
						<Content text='For every 5 referrals who buy a subscription, you get 1 TF2 key (Mann Co. Supply Crate Key). The keys received for referrals can be withdrawn to your inventory on Steam by requesting the withdrawal of keys through support.' />
						<Title text='Note' />
						<Content text='The referral code can only be used once. You cannot use your referral code.' />
					</div>
				)
				break
			case 'promo':
				_title = { 0: 'Промокоды' }[currentLang] || 'Promo codes'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={7}>
							<MainTitle text='Как работают промокоды?' />
							<Content text='Используя промокод, Вы получаете бесплатную награду: ключи на баланс или подписку. Активировать промокод можно в своем {профиле|/profile}.' />
							<Image src='Resources/Faq/Promo/1_ru.png' alt='promo' height={200} />
							<Title text='Примечание' />
							<Content text='Активировать промокод на подписку можно только в том случае, если у Вас нет активной подписки.' />
							<MainTitle text='Где найти промокод?' />
							<Content text='Промокоды можно найти в наших соц. сетях. Иногда они также появляются на главной странице сайта.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={7}>
						<MainTitle text='How do promo codes work?' />
						<Content text='Using a promo code, you get a free reward: keys to a balance or a subscription. You can activate the promo code in your {profile|/profile}.' />
						<Image src='Resources/Faq/Promo/1_en.png' alt='promo' height={200} />
						<Title text='Note' />
						<Content text='You can activate a promotional code for a subscription only if you do not have an active subscription.' />
						<MainTitle text='Where can I find a promo code?' />
						<Content text='Promo codes can be found in our social networks. Sometimes they also appear on the main page of the site.' />
					</div>
				)
				break
			case 'security':
				_title = { 0: 'Безопасность' }[currentLang] || 'Security'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={8}>
							<MainTitle text='Как не потерять свои ключи?' />
							<List
								title={<>Список советов:</>}
								items={[
									getTextJsxWithUrl('Пополняйте баланс только через сайт в разделе {баланса|/balance}.'),
									<>Проверяйте домен, на котором Вы находитесь: единственный наш домен – tablevv.com.</>,
									<>Проверяйте, кому отправляете ключи. У нас только 9 ботов.</>,
								]}
							/>
							<List
								title={<>Список наших ботов:</>}
								items={[...Array(9).keys()].map(i => getTextJsxWithUrl(`{vvTable #${i + 1}|https://steamcommunity.com/id/vvtable_${i + 1}|d}`))}
							/>
							<MainTitle text='Как не потерять свой аккаунт?' />
							<List
								title={<>Список советов:</>}
								items={[<>Никогда не показывайте никому свои файлы cookie.</>, <>Никогда не переходите по неизвестным ссылкам.</>]}
							/>
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={8}>
						<MainTitle text='How not to lose your keys?' />
						<List
							title={<>List of tips:</>}
							items={[
								getTextJsxWithUrl('Top up your balance only through the website in the {balance|/sub} section.'),
								<>Check the domain you are on: our only domain is tablevv.com.</>,
								<>Check who you send keys to. We only have 9 bots.</>,
							]}
						/>
						<List
							title={<>List of our bots:</>}
							items={[...Array(9).keys()].map(i => getTextJsxWithUrl(`{vvTable #${i + 1}|https://steamcommunity.com/id/vvtable_${i + 1}|d}`))}
						/>
						<MainTitle text='How not to lose your account?' />
						<List title={<>List of tips:</>} items={[<>Never show your cookies to anyone.</>, <>Never follow unknown links.</>]} />
					</div>
				)
				break
			default:
				setCurrentHash('auth')
				_title = { 0: 'Авторизация' }[currentLang] || 'Authorization'
				_jsx = {
					0: (
						<div className={styles.animatedElement} key={0}>
							<MainTitle text='Как начать работать с сайтом?' />
							<Content text='Для того, чтобы начать пользоваться нашим сайтом, Вам необходимо авторизоваться через Steam. Это можно сделать, нажав на центральную кнопку «Начать» или же на кнопку «Войти» в правом верхнем углу на {главной странице|/} vvTable.' />
							<Image src='Resources/Faq/Auth/1_ru.png' alt='auth' height={400} />
							<Content text='После этого Вас перенаправит на сайт входа в Steam аккаунт. Войдя в свой аккаунт, Вы сможете использовать все разделы сайта.' />
							<Title text='Примечание' />
							<Content text='Если Вам по каким-либо причинам не удаётся пройти авторизацию, обязательно обратитесь в нашу поддержку в специальном разделе или по почте {support@tablevv.com|mailto:support@tablevv.com}.' />
						</div>
					),
				}[currentLang] || (
					<div className={styles.animatedElement} key={0}>
						<MainTitle text='How to start working with the site?' />
						<Content text='In order to start using our site, you need to log in through Steam. This can be done by clicking on the central «Start» button or on the «Log in» button in the upper right corner on the vvTable {main page|/}.' />
						<Image src='Resources/Faq/Auth/1_en.png' alt='auth' height={400} />
						<Content text='After that, you will be redirected to the login site for your Steam account. By logging into your account, you will be able to use all sections of the site.' />
						<Title text='Note' />
						<Content text='If for some reason you are unable to pass authorization, be sure to contact our support in a special section or by mail {support@tablevv.com|mailto:support@tablevv.com}.' />
					</div>
				)
				break
		}

		setTitle(_title)
		setContent(_jsx)

		window.scrollTo(0, 0)
	}, [location.hash, currentLang])

	return (
		<div className={styles.faqContent}>
			<div className={styles.faqQuestions}>
				<h1 className={styles.fqTitle}>{{ 0: 'FAQ - Ответы на вопросы' }[currentLang] || 'FAQ - Answers on questions'}</h1>
				<div className={styles.fqContent}>
					<NavLink to={'#auth'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'auth' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Авторизация' }[currentLang] || 'Authorization'}
					</NavLink>
					<NavLink to={'#profile'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'profile' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Профиль' }[currentLang] || 'Profile'}
					</NavLink>
					<NavLink to={'#table'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'table' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Таблица' }[currentLang] || 'Table'}
					</NavLink>
					<NavLink to={'#balance'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'balance' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Баланс' }[currentLang] || 'Balance'}
					</NavLink>
					<NavLink to={'#sub'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'sub' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Подписка' }[currentLang] || 'Subscription'}
					</NavLink>
					<NavLink to={'#support'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'support' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Поддержка' }[currentLang] || 'Support'}
					</NavLink>
					<NavLink to={'#settings'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'settings' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Настройки' }[currentLang] || 'Settings'}
					</NavLink>
					<NavLink to={'#referrals'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'referrals' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Реферальная система' }[currentLang] || 'Referral system'}
					</NavLink>
					<NavLink to={'#promo'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'promo' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Промокоды' }[currentLang] || 'Promo codes'}
					</NavLink>
					<NavLink to={'#security'} className={`${styles.fqNavItem} btn-simple-hov${currentHash === 'security' ? ' ' + styles.active : ''}`}>
						{{ 0: 'Безопасность' }[currentLang] || 'Security'}
					</NavLink>
				</div>
			</div>
			<div className={styles.faqAnswerContent}>
				<h2 className={styles.faTitle}>{title}</h2>
				<div className={styles.faContent}>{content}</div>
			</div>
		</div>
	)
}

export default FaqPage
